import React, { FC, useEffect, useMemo, useState } from 'react';
import { AutoSizer, CellMeasurerCache, RowMouseEventHandlerParams, SortDirectionType, Table } from 'react-virtualized';
import theme from './TableUIKitComponent.scss';
import { TTable, TTableData } from './TableUIKit.types';
import { getColumns, getInitialSortDirection, getInitialSortState, getRowClassName } from './utils/Table.utils';

const ROW_HEADER_HEIGHT = 48;

const ROW_HEIGHT = 56;

export const TableUIKit: FC<TTable> = (props) => {
    const {
        columns,
        withCkeckBox,
        tableData,
        minWidth,
        disableRowClick,
        checkRows,
        hadnleRowDoubleClick,
        onColumnClick,
        onHeaderClick,
        onRowClick,
    } = props;
    const [sortDirectionState, setSortDirectionState] = useState<SortDirectionType>(getInitialSortDirection(columns));
    const [sortByState, setSortByState] = useState<string>(getInitialSortState(columns));
    const cellHeightCache = new CellMeasurerCache({
        fixedWidth: true,
        minHeight: ROW_HEIGHT,
    });

    useEffect(() => {
        cellHeightCache.clearAll();
    }, [tableData]);

    const sortedList: TTableData[] = useMemo(() => {
        const sortFunction = columns.find((column) => column.dataKey === sortByState)?.sortFunction;
        if (sortFunction) {
            if (sortDirectionState === 'ASC') {
                return tableData.sort(sortFunction);
            }

            return tableData.sort(sortFunction).reverse();
        }

        return tableData;
    }, [sortDirectionState, sortByState, tableData]);

    const rowGetter = ({ index }) => sortedList[index];

    const sort = ({ sortBy, sortDirection }: { sortBy: string; sortDirection: SortDirectionType }) => {
        if (!sortBy) return;

        setSortByState(sortBy);
        setSortDirectionState(sortDirection);
    };

    const handleRowClick = (params: RowMouseEventHandlerParams) => {
        if (disableRowClick) return;

        const {
            rowData,
        }: {
            event: React.MouseEvent<any>;
            rowData: TTableData;
        } = params;

        const { checked, disabled } = rowData;
        if (!disabled && withCkeckBox && checkRows) {
            checkRows([rowData], !checked);
        } else if (onRowClick) {
            onRowClick(rowData);
        }
    };

    return (
        <div className={theme.tableContainer}>
            <AutoSizer>
                {({ height, width }) => (
                    <Table
                        width={minWidth && width < minWidth ? minWidth : width}
                        height={height}
                        rowHeight={ROW_HEIGHT}
                        className={theme.table}
                        headerHeight={ROW_HEADER_HEIGHT}
                        rowCount={tableData.length}
                        rowGetter={rowGetter}
                        rowClassName={({ index }) =>
                            getRowClassName(index, sortedList)
                                .map((cn) => theme[cn])
                                .join(' ')
                        }
                        sort={sort}
                        sortBy={sortByState}
                        sortDirection={sortDirectionState}
                        onRowClick={(params) => handleRowClick(params)}
                        onRowDoubleClick={hadnleRowDoubleClick}
                        onColumnClick={onColumnClick}
                        onHeaderClick={onHeaderClick}
                    >
                        {getColumns({
                            withCkeckBox: !!withCkeckBox,
                            columns,
                            width,
                            cache: cellHeightCache,
                            sortedList,
                            checkRows,
                        })}
                    </Table>
                )}
            </AutoSizer>
        </div>
    );
};

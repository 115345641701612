import {
    FETCH_NODES_REQUEST,
    FETCH_NODES_REQUEST_SUCCESS,
    FETCH_NODES_WITH_ATTRIBUTES_REQUEST,
    FETCH_NODES_WITH_ATTRIBUTES_REQUEST_SUCCESS,
} from '../actionsTypes/nodes.actionTypes';
import { TFetchNodesAction, TFetchNodesSuccessAction, TFetchNodesWithAttributesAction } from './nodes.actions.types';
import { NodeId, Node } from '../serverapi/api';

export const fetchNodes = (nodes: NodeId[]): TFetchNodesAction => {
    return {
        type: FETCH_NODES_REQUEST,
        payload: {
            nodes,
        },
    };
};

export const fetchNodesWithAttributes = (nodeIds: NodeId[]): TFetchNodesWithAttributesAction => {
    return {
        type: FETCH_NODES_WITH_ATTRIBUTES_REQUEST,
        payload: {
            nodeIds,
        },
    };
};

export const fetchNodesSuccess = (nodes: Node[]): TFetchNodesSuccessAction => ({
    type: FETCH_NODES_REQUEST_SUCCESS,
    payload: { nodes },
});

export const fetchNodesWithAttributesSuccess = (nodes: Node[]): TFetchNodesSuccessAction => ({
    type: FETCH_NODES_WITH_ATTRIBUTES_REQUEST_SUCCESS,
    payload: { nodes },
});

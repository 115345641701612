import type { TWikiEditorDispatchProps, TWikiEditorProps, TWikiEditorStateProps } from '../components/WikiEditor.types';
import type { TTabComponentProps } from '@/modules/Workspace/Workspace.types';
import type { IWikiNode } from '@/models/bpm/bpm-model-impl.types';
import type { TRootState } from '@/reducers/root.reducer.types';
import type { NodeId, WikiContent } from '../../../serverapi/api';
import type { TWorkspaceTab } from '@/models/tab.types';
import type { TDispatch } from '@/utils/types';
import WikiEditor from '../components/WikiEditor.component';
import { connect } from 'react-redux';
import { loadComments } from '@/actions/comments.actions';
import {
    wikiChangeRequest,
    wikiDropDraggedNode,
    wikiUploadFile,
    wikiUploadImage,
} from '@/actions/entities/wiki.actions';
import { PictureSymbolConstants } from '@/models/pictureSymbolConstants';
import { ServerSelectors } from '@/selectors/entities/server.selectors';
import { CommentsSelectors } from '@/selectors/comments.selectors';
import { compareNodeIds } from '@/utils/nodeId.utils';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { openNodeById } from '@/actions/openNode.actions';
import { getEditorData, getWikiImageSrc, isNewEditorData, WIKI_CONTENT_PROP_NAME } from '../WikiEditor.utils';
import { PrincipalsSelectors } from '@/selectors/principals.selectors';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { instancesWikiEditorMap } from '@/mxgraph/wiki-editor-instance-map';

const mapStateToProps = (state: TRootState, ownProps: TTabComponentProps): TWikiEditorStateProps => {
    const tab: TWorkspaceTab | undefined = TabsSelectors.byId(ownProps.tab.nodeId)(state);
    const content = tab?.content as IWikiNode;
    const nodeId = tab?.nodeId as NodeId;
    const { zoomLevel } = tab?.params || {};
    const { createdBy, updatedBy, updatedAt } = content;
    const server = ServerSelectors.server(content.serverId)(state);
    const baseUrl = server?.url ? `${server.url}/${PictureSymbolConstants.DOWNLOAD_LINK}` : '';
    const commentsEnabledSchemesIds: NodeId[] = CommentsSelectors.commentsEnabledSchemesIds(state);
    const isShowCommentsPanel = commentsEnabledSchemesIds.some((id) => compareNodeIds(id, nodeId));
    const creator = createdBy ? PrincipalsSelectors.getUser(createdBy)(state) : undefined;
    const updator = updatedBy ? PrincipalsSelectors.getUser(updatedBy)(state) : undefined;

    return {
        id: 'WikiModel',
        value: getEditorData(content),
        isNewEditor: isNewEditorData(content),
        zoomLevel,
        baseUrl,
        isShowCommentsPanel,
        modelId: nodeId,
        modelContent: content,
        title: content.name,
        createdBy: creator,
        updatedBy: updator,
        updatedAt: updatedAt,
    };
};

const mapDispatchToProps = (dispatch: TDispatch): TWikiEditorDispatchProps => {
    return {
        onRendered: (editor, modelId: NodeId) => {
            if (editor) {
                instancesWikiEditorMap.set(modelId, editor);
            }

            dispatch(loadComments(modelId));
        },
        onUnmount: (modelId: NodeId) => {
            if (modelId) {
                instancesWikiEditorMap.delete(modelId);
            }
        },
        onChange: (value: string | WikiContent, modelId: NodeId, modelContent: IWikiNode, isNewEditor: boolean) => {
            const source = isNewEditor ? modelContent.source : (value as string);
            const content = !isNewEditor ? modelContent[WIKI_CONTENT_PROP_NAME] : (value as WikiContent);

            dispatch(wikiChangeRequest(modelId, source, content));
        },
        handlers: {
            imageLinkMapper: (src: string, baseUrl: string) =>
                src?.startsWith('data:image/') ? src : `${baseUrl}/${src}/`,
            readFromClipboard: (callback) => {
                navigator.clipboard.readText().then(callback);
            },
            copyToClipboard: (text: string) => {
                navigator.clipboard.writeText(text);
            },
            openComment: (
                modelId: NodeId,
                threadId: string,
                commentId: string,
                onSubmit: (threadId: string) => void,
            ) => {
                dispatch(
                    openDialog(DialogType.WRITE_COMMENT_DIALOG, {
                        modelId,
                        parentId: threadId,
                        threadId,
                        commentId,
                        onSubmit,
                    }),
                );
            },
            openInternalLink: (linkId: string, modelId: NodeId) => {
                dispatch(openNodeById({ nodeId: { ...modelId, id: linkId } }));
            },
            uploadFile: (file: File, fileId: string, modelId: NodeId) => {
                const fileNodeId = { ...modelId, id: fileId };

                dispatch(wikiUploadFile({ file, fileId: fileNodeId, modelId }));

                return { nodeId: fileNodeId };
            },
            uploadImage: (file: File, fileId: string, modelId: NodeId) => {
                const fileNodeId = { ...modelId, id: fileId };

                dispatch(wikiUploadImage({ file, fileId: fileNodeId, modelId }));

                return {
                    src: getWikiImageSrc(fileNodeId, modelId),
                };
            },
            dropNode: (position: number, draggedNodeId: NodeId, baseUrl: string, modelId: NodeId) => {
                const src = `${baseUrl}/${draggedNodeId.repositoryId}/${draggedNodeId.id}/`;

                dispatch(wikiDropDraggedNode({ src, position, modelId }));
            },
        },
    };
};

const mergeProps = (stateProps: TWikiEditorStateProps, dispatchProps: TWikiEditorDispatchProps): TWikiEditorProps => {
    const { baseUrl, modelId, modelContent, isNewEditor } = stateProps;
    const { handlers } = dispatchProps;

    const newDispatchProps = {
        ...dispatchProps,
        onRendered: (editor) => {
            return dispatchProps.onRendered(editor, modelId);
        },
        onChange: (value: string | WikiContent) => {
            return dispatchProps.onChange(value, modelId, modelContent, isNewEditor);
        },
        handlers: {
            ...handlers,
            imageLinkMapper: (src: string) => {
                return handlers.imageLinkMapper(src, baseUrl);
            },
            openComment: (commentId: string, threadId: string, onSubmit: (threadId: string) => void) => {
                return handlers.openComment(modelId, threadId, commentId, onSubmit);
            },
            openInternalLink: (linkId: string) => {
                return handlers.openInternalLink(linkId, modelId);
            },
            uploadFile: (file: File, fileId: string) => {
                return handlers.uploadFile(file, fileId, modelId);
            },
            uploadImage: (file: File, fileId: string) => {
                return handlers.uploadImage(file, fileId, modelId);
            },
            dropNode: (position: number, nodeId: NodeId) => {
                return handlers.dropNode(position, nodeId, baseUrl, modelId);
            },
        },
    };

    return { ...stateProps, ...newDispatchProps };
};

export const WikiEditorContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(WikiEditor);

import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { Node, NodeId } from '../serverapi/api';
import { TNodesState } from '../reducers/nodes.reducer.types';
import { compareNodeIds } from '../utils/nodeId.utils';
import { TreeSelectors } from './tree.selectors';
import { TNodeWithPresetId } from './types/nodesSelector.types';

const getState = (state: TRootState) => state.nodes;
const getRootState = (state: TRootState) => state;

export const nodesSelector = createSelector<TRootState, TNodesState, Node[]>(getState, (state) => state.values());

export const nodeSelectorByNodeId = (nodeId) =>
    createSelector<TRootState, TNodesState, Node | undefined>(getState, (state) =>
        state.values().find((node) => compareNodeIds(node.nodeId, nodeId)),
    );

export const getNodesWithPresetIdByIds = (nodeIds: NodeId[]) =>
    createSelector<TRootState, TRootState, TNodesState, TNodeWithPresetId[]>(
        getRootState,
        getState,
        (rootState: TRootState, nodeState: TNodesState) => {
            const result: (Node & { presetId: string })[] = [];
            nodeIds.forEach((nodeId) => {
                const node: Node | undefined = nodeState.byNodeId.get(nodeId);
                if (node) {
                    const presetId: string = TreeSelectors.presetById(nodeId)(rootState);
                    const nodeWithPresetId = { ...node, presetId };
                    result.push(nodeWithPresetId);
                }
            });

            return result;
        },
    );

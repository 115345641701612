import { NodeId, ReportNode } from '@/serverapi/api';
import {
    TReportAddColumnAction,
    TReportAddNodesAction,
    TReportClearSelectColumnAction,
    TReportColumnData,
    TReportCreateAction,
    TReportDeleteColumnAction,
    TReportDropNodesAction,
    TReportOpenAction,
    TReportOpenByNodeIdAction,
    TReportRequestSuccessAction,
    TReportSaveRequestAction,
    TReportSaveRequestFailureAction,
    TReportSaveRequestSuccessAction,
    TReportSelectColumnAction,
    TReportSetColumnDataAction,
} from './report.actions.types';
import {
    REPORT_CREATE,
    REPORT_OPEN,
    REPORT_SAVE_REQUEST,
    REPORT_SAVE_REQUEST_SUCCESS,
    REPORT_SAVE_REQUEST_FAILURE,
    REPORT_OPEN_BY_NODE_ID,
    REPORT_REQUEST_SUCCESS,
    REPORT_ADD_NODES,
    REPORT_DROP_NODES,
    REPORT_SET_COLUMN_DATA,
    REPORT_DELETE_COLUMN,
    REPORT_ADD_COLUMN,
    REPORT_SELECT_COLUMN,
    REPORT_CLEAR_SELECT_COLUMN,
} from './report.actionTypes';
import { EditorMode } from '@/models/editorMode';

export const reportCreate = (report: ReportNode): TReportCreateAction => ({
    type: REPORT_CREATE,
    payload: {
        report,
    },
});

export const reportSaveRequest = (report: ReportNode): TReportSaveRequestAction => ({
    type: REPORT_SAVE_REQUEST,
    payload: {
        report,
    },
});

export const reportSaveRequestSuccess = (report: ReportNode): TReportSaveRequestSuccessAction => ({
    type: REPORT_SAVE_REQUEST_SUCCESS,
    payload: {
        report,
    },
});

export const reportSaveRequestFailure = (): TReportSaveRequestFailureAction => ({
    type: REPORT_SAVE_REQUEST_FAILURE,
});

export const reportOpen = (report: ReportNode, mode?: EditorMode): TReportOpenAction => ({
    type: REPORT_OPEN,
    payload: {
        report,
        mode,
    },
});

export const reportOpenByNodeId = (nodeId: NodeId): TReportOpenByNodeIdAction => ({
    type: REPORT_OPEN_BY_NODE_ID,
    payload: { reportNodeId: nodeId },
});

export const reportRequestSuccess = (report: ReportNode): TReportRequestSuccessAction => ({
    type: REPORT_REQUEST_SUCCESS,
    payload: {
        report,
    },
});

export const reportDropNodes = (reportNodeId: NodeId, initDroppedNodeId: NodeId): TReportDropNodesAction => ({
    type: REPORT_DROP_NODES,
    payload: {
        reportNodeId,
        initDroppedNodeId,
    },
});

export const reportAddNodes = (reportNodeId: NodeId, addedNodeIds: NodeId[]): TReportAddNodesAction => ({
    type: REPORT_ADD_NODES,
    payload: {
        reportNodeId,
        addedNodeIds,
    },
});

export const reportSetColumnData = (
    reportNodeId: NodeId,
    columnId: string,
    data: TReportColumnData,
): TReportSetColumnDataAction => ({
    type: REPORT_SET_COLUMN_DATA,
    payload: {
        reportNodeId,
        columnId,
        data,
    },
});

export const reportDeleteColumn = (reportNodeId: NodeId, columnId: string): TReportDeleteColumnAction => ({
    type: REPORT_DELETE_COLUMN,
    payload: {
        reportNodeId,
        columnId,
    },
});

export const reportAddColumn = (reportNodeId: NodeId, selectedColumnId?: string): TReportAddColumnAction => ({
    type: REPORT_ADD_COLUMN,
    payload: {
        reportNodeId,
        selectedColumnId,
    },
});

export const reportSelectColumn = (reportNodeId: NodeId, columnId: string): TReportSelectColumnAction => ({
    type: REPORT_SELECT_COLUMN,
    payload: {
        reportNodeId,
        columnId,
    },
});

export const reportClearSelectColumn = (reportNodeId: NodeId): TReportClearSelectColumnAction => ({
    type: REPORT_CLEAR_SELECT_COLUMN,
    payload: {
        reportNodeId,
    },
});

import React from 'react';
import { TableHeaderProps } from 'react-virtualized';
import { SortIndicator } from '../../../AdminTools/Methodology/components/MethodologySettingPage/SortIndicator.component';
import theme from './TableUIKitComponent.scss';

type TColumnHeaderProps = {
    data: TableHeaderProps;
    label: string;
    withoutSorter?: boolean;
    isEmptyData?: boolean;
};

export const ColumnHeader = (props: TColumnHeaderProps) => {
    const { data, label, withoutSorter, isEmptyData } = props;
    const { sortDirection, sortBy, dataKey, columnData } = data;

    return (
        <div
            className={`${theme.headerCell} ${columnData.selected ? theme.selectedHeaderCell : ''} ${
                isEmptyData ? theme.emptyDataSelectedHeaderCell : ''
            }`}
        >
            <div className={theme.sortableColumn}>
                <span>{label}</span>
                {!withoutSorter ? <SortIndicator sortDirection={sortDirection} isActive={dataKey === sortBy} /> : null}
            </div>
        </div>
    );
};

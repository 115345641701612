import React, { FC, useState } from 'react';
import theme from './ReportEditorSidePanel.scss';
import { NavigatorPanel } from '@/modules/Navigator/components/NavigatorPanel/NavigatorPanel.component';
import { TNavigatorTab } from '@/reducers/navigator.reducer.types';
import { useIntl } from 'react-intl';
import messages from './ReportEditorSidebar.messages';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import atributeSelectIcon from '@/resources/icons/atributeSelect.svg';
import { Input } from '@/modules/UIKit/components/Input/Input.component';
import { NodeId, ReportColumnData } from '@/serverapi/api';
import { AttributeSelectDialog } from '../AttributeSelectDialog/AttributeSelectDialog.component';
import deleteIcon from '@/resources/icons/Deleted.svg';
import { useDispatch } from 'react-redux';
import { reportClearSelectColumn, reportDeleteColumn } from '../actions/report.actions';

type TReportEditorSidePanelProps = {
    reportNodeId: NodeId;
    disableDelete: boolean;
    currentColumn?: ReportColumnData;
};

export const ReportEditorSidePanel: FC<TReportEditorSidePanelProps> = ({
    reportNodeId,
    disableDelete,
    currentColumn,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [isAttributeDialogOpen, setIsAttributeDialogOpen] = useState<boolean>(false);

    const deleteColumnHandler = () => {
        if (currentColumn?.columnId) {
            dispatch(reportDeleteColumn(reportNodeId, currentColumn?.columnId));
        }
    };

    const onCloseHandler = () => {
        dispatch(reportClearSelectColumn(reportNodeId));
    };

    return (
        <NavigatorPanel
            titleProps={{
                title: intl.formatMessage(messages.colSettings),
            }}
            type={TNavigatorTab.ReportSettings}
            className={theme.reportEditorSidePanel}
            onClose={onCloseHandler}
        >
            <div className={theme.sidePanelBody}>
                <div className={theme.group}>
                    <div className={theme.attributeInput}>
                        <Input
                            className={theme.input}
                            name="attributeInput"
                            placeholder={intl.formatMessage(messages.attributePlaceholder)}
                            disabled
                            value={currentColumn?.columnName || ''}
                        />
                        <Button icon={atributeSelectIcon} width={30} onClick={() => setIsAttributeDialogOpen(true)} />
                    </div>
                </div>
                <div className={theme.container}>
                    <Button disabled={disableDelete} icon={deleteIcon} onClick={deleteColumnHandler}>
                        {intl.formatMessage(messages.deleteColumn)}
                    </Button>
                </div>
            </div>
            <AttributeSelectDialog
                reportNodeId={reportNodeId}
                open={isAttributeDialogOpen}
                currentColumn={currentColumn}
                setOpen={setIsAttributeDialogOpen}
            />
        </NavigatorPanel>
    );
};

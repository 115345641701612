import { put, select, takeEvery } from 'redux-saga/effects';
import { TFetchNodesAction, TFetchNodesWithAttributesAction } from '../actions/nodes.actions.types';
import { FETCH_NODES_REQUEST, FETCH_NODES_WITH_ATTRIBUTES_REQUEST } from '../actionsTypes/nodes.actionTypes';
import { TreeDaoService } from '../services/dao/TreeDaoService';
import { Node } from '../serverapi/api';
import { fetchNodesSuccess, fetchNodesWithAttributesSuccess } from '../actions/nodes.actions';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { SearchDaoService } from '@/services/dao/SearchDAOService';

function* handleFetchNodes(action: TFetchNodesAction) {
    const { nodes } = action.payload;
    const serverId = yield select(ServerSelectors.serverId);
    const treeNodes: Node[] = yield TreeDaoService.getNodes(nodes, serverId);
    yield put(fetchNodesSuccess(treeNodes));
}

function* handleFetchNodesWithAttributes(action: TFetchNodesWithAttributesAction) {
    const { nodeIds } = action.payload;

    const serverId: string = yield select(ServerSelectors.serverId);

    if (nodeIds.length !== 0) {
        const searchResults: Node[] = yield SearchDaoService.getNodesWithAttributes(serverId, nodeIds);
        yield put(fetchNodesWithAttributesSuccess(searchResults));
    }
}

export function* nodesSaga() {
    yield takeEvery(FETCH_NODES_REQUEST, handleFetchNodes);
    yield takeEvery(FETCH_NODES_WITH_ATTRIBUTES_REQUEST, handleFetchNodesWithAttributes);
}

import { defineMessages } from 'react-intl';

export default defineMessages({
    cut: {
        id: 'WikiEditor.cut',
        defaultMessage: 'Вырезать',
    },
    paste: {
        id: 'WikiEditor.paste',
        defaultMessage: 'Вставить',
    },
    copy: {
        id: 'WikiEditor.copy',
        defaultMessage: 'Копировать',
    },
    delete: {
        id: 'WikiEditor.delete',
        defaultMessage: 'Удалить',
    },
    wikiModel: {
        id: 'WikiEditor.wikiModel',
        defaultMessage: 'Wiki-страница',
    },
    toggleComment: {
        id: 'WikiEditor.toggleComment',
        defaultMessage: 'Цитировать',
    },
    createdBy: {
        id: 'WikiEditor.createdBy',
        defaultMessage: 'Создал(а)',
    },
    updatedBy: {
        id: 'WikiEditor.updatedBy',
        defaultMessage: 'редактировал(а)',
    },
    deleteImage: {
        id: 'ImageRenderer.deleteImage',
        defaultMessage: 'Удалить картинку',
    },
    setBackground: {
        id: 'TableRenderer.setBackground',
        defaultMessage: 'Фон ячейки',
    },
    insertColumn: {
        id: 'TableRenderer.insertColumn',
        defaultMessage: 'Вставить столбец справа',
    },
    insertRow: {
        id: 'TableRenderer.insertRow',
        defaultMessage: 'Вставить строку ниже',
    },
    deleteColumn: {
        id: 'TableRenderer.deleteColumn',
        defaultMessage: 'Удалить столбец',
    },
    deleteRow: {
        id: 'TableRenderer.deleteRow',
        defaultMessage: 'Удалить строку',
    },
    clearCell: {
        id: 'TableRenderer.clearCell',
        defaultMessage: 'Очистить содержимое ячейки',
    },
    unionCells: {
        id: 'TableRenderer.unionCells',
        defaultMessage: 'Объединить ячейки',
    },
    divideCell: {
        id: 'TableRenderer.divideCell',
        defaultMessage: 'Разделить ячейку',
    },
    headerRow: {
        id: 'TableRenderer.headerRow',
        defaultMessage: 'Строка заголовков',
    },
    sortAZ: {
        id: 'TableRenderer.sortAZ',
        defaultMessage: 'Сортировка от А до Я',
    },
    deleteTable: {
        id: 'TableRenderer.deleteTable',
        defaultMessage: 'Удалить таблицу',
    },
});

export const REPORT_CREATE = 'REPORT_CREATE';

export const REPORT_SAVE_REQUEST = 'REPORT_SAVE_REQUEST';

export const REPORT_SAVE_REQUEST_SUCCESS = 'SAVE_REQUESTS';

export const REPORT_SAVE_REQUEST_FAILURE = 'REPORT_SAVE_REQUEST_FAILURE';

export const REPORT_OPEN = 'REPORT_OPEN';

export const REPORT_OPEN_BY_NODE_ID = 'REPORT_OPEN_BY_NODE_ID';

export const REPORT_REQUEST_SUCCESS = 'REPORT_REQUEST_SUCCESS';

export const REPORT_DROP_NODES = 'REPORT_DROP_NODES';

export const REPORT_ADD_NODES = 'REPORT_ADD_NODES';

export const REPORT_SET_COLUMN_DATA = 'REPORT_SET_COLUMN_DATA';

export const REPORT_DELETE_COLUMN = 'REPORT_DELETE_COLUMN';

export const REPORT_ADD_COLUMN = 'REPORT_ADD_COLUMN';

export const REPORT_SELECT_COLUMN = 'REPORT_SELECT_COLUMN';

export const REPORT_CLEAR_SELECT_COLUMN = 'REPORT_CLEAR_SELECT_COLUMN';

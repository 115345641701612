import { TRootState } from '../../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import {
    ReportNode,
    NodeId,
    AttributeValue,
    ReportColumnData,
    AttributeType,
    PrincipalDescriptor,
} from '../../../serverapi/api';
import { TReportState } from '../reducers/report.reducer.types';
import { TTableData } from '@/modules/UIKit/components/Table/TableUIKit.types';
import { getNodesWithPresetIdByIds } from '@/selectors/nodes.selector';
import { TNodeWithPresetId } from '@/selectors/types/nodesSelector.types';
import { mapNodeToSystemAttributeValues } from '@/mxgraph/overlays/BPMMxCellOverlay.utils';
import { storageValueToString } from '@/modules/ObjectPropertiesDialog/components/utils';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { LocalesService } from '@/services/LocalesService';
import { PrincipalsSelectors } from '@/selectors/principals.selectors';
import modelTypeMessages from '@/models/modelType.messages';
import { systemAttributeTypes } from '@/utils/constants/systemAttributes.const';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { UserProfileSelectors } from '@/selectors/userProfile.selectors';
import { AttributeTypeSelectors } from '@/selectors/attributeType.selectors';
import { TCurrentUserProfile } from '@/reducers/userProfile.reducer.types';
import { ProfileBllService } from '@/services/bll/ProfileBllService';
import { Locale } from '@/modules/Header/components/Header/header.types';

const reportStateSelector = (state: TRootState): TReportState => state.report;

export namespace ReportSelectors {
    export const byId = (nodeId: NodeId) =>
        createSelector<TRootState, TReportState, ReportNode | undefined>(reportStateSelector, (state) =>
            state.get(nodeId),
        );

    export const byIds = (nodeIds: NodeId[]) =>
        createSelector<TRootState, TReportState, ReportNode[]>(reportStateSelector, (state) => {
            const reportArr: ReportNode[] = nodeIds
                .map((nodeId) => {
                    const report: ReportNode | undefined = state.get(nodeId);
                    return report;
                })
                .filter((element): element is ReportNode => !!element);

            return reportArr;
        });

    export const selectedColumnId = (nodeId: NodeId) =>
        createSelector<TRootState, TReportState, string | undefined>(
            reportStateSelector,
            (state) => state.get(nodeId)?.selectedColumnId,
        );

    export const isReportUnsaved = (nodeId: NodeId) =>
        createSelector<TRootState, TReportState, boolean>(reportStateSelector, (state) => {
            if (state.get(nodeId)?.unsaved === undefined) return true;

            return !!state.get(nodeId)?.unsaved;
        });

    export const getAccessibleSystemAttributeTypes = (reportId: NodeId) =>
        createSelector<TRootState, ReportNode | undefined, AttributeType[]>(byId(reportId), (report) => {
            const reportColumns: ReportColumnData[] = report?.reportData?.columns || [];

            return systemAttributeTypes.filter(
                (attrType) =>
                    !reportColumns.some(
                        (column) => column.attributeType === 'SYSTEM' && column.attributeTypeId === attrType.id,
                    ),
            );
        });

    export const getAccessibleAttributeTypes = (reportId: NodeId) =>
        createSelector<
            TRootState,
            ReportNode | undefined,
            TCurrentUserProfile | undefined,
            AttributeType[],
            AttributeType[]
        >(
            byId(reportId),
            UserProfileSelectors.selectUserProfileByNodeId(reportId),
            AttributeTypeSelectors.allInPresetByNodeIdSorted(reportId),
            (report, profile, attributeTypes) => {
                const reportColumns: ReportColumnData[] = report?.reportData?.columns || [];

                return attributeTypes.filter(
                    (attrType) =>
                        ProfileBllService.isAttributeViewable(profile, attrType.id) &&
                        !reportColumns.some(
                            (column) => column.attributeType === 'USER' && column.attributeTypeId === attrType.id,
                        ),
                );
            },
        );

    export const getReportTableData = (reportId: NodeId, nodeIds: NodeId[], checedIds: string[]) =>
        createSelector<
            TRootState,
            TNodeWithPresetId[],
            ReportNode | undefined,
            string,
            AttributeType[],
            PrincipalDescriptor[],
            Locale,
            TTableData[]
        >(
            getNodesWithPresetIdByIds(nodeIds),
            byId(reportId),
            TreeSelectors.presetById(reportId),
            getAccessibleAttributeTypes(reportId),
            PrincipalsSelectors.getAll,
            getCurrentLocale,
            (nodes, report, reportPresetId, accessibleAttributeTypes, principals, locale) => {
                const intl = LocalesService.useIntl();
                const reportColumns: ReportColumnData[] = report?.reportData?.columns || [];
                const tableData: TTableData[] = nodes.map((node) => {
                    const id = `${node.nodeId.repositoryId}_${node.nodeId.id}`;
                    const res: { [id: string]: string | boolean } = {
                        id,
                        checked: checedIds.includes(id),
                    };
                    const systemAttributeValues: AttributeValue[] = mapNodeToSystemAttributeValues(node, undefined);

                    reportColumns.forEach((reportColumn) => {
                        let stringAttributeValue = '';

                        if (node.nodeId.repositoryId !== reportId.repositoryId) {
                            res[`${reportColumn.attributeType}_${reportColumn.attributeTypeId}`] = stringAttributeValue;
                        }

                        if (reportColumn.attributeType === 'SYSTEM') {
                            const systemAttributeValue: AttributeValue | undefined = systemAttributeValues.find(
                                (value) => value.typeId === reportColumn.attributeTypeId,
                            );

                            stringAttributeValue = storageValueToString(systemAttributeValue, locale, {
                                system: true,
                                attributeTypes: systemAttributeTypes,
                                principals: principals,
                            });

                            if (reportColumn.attributeTypeId === 'nodeType') {
                                stringAttributeValue =
                                    modelTypeMessages[node.type] && intl.formatMessage(modelTypeMessages[node.type]);
                            }
                        }

                        if (reportColumn.attributeType === 'USER') {
                            const attributeValue: AttributeValue | undefined = node.attributes?.find(
                                (attribute) => attribute.typeId === reportColumn.attributeTypeId,
                            );
                            if (attributeValue && node.presetId === reportPresetId) {
                                stringAttributeValue = storageValueToString(attributeValue, locale, {
                                    system: false,
                                    attributeTypes: accessibleAttributeTypes,
                                    principals: principals,
                                });
                            }
                        }

                        res[`${reportColumn.attributeType}_${reportColumn.attributeTypeId}`] = stringAttributeValue;
                    });
                    return res;
                });

                return tableData;
            },
        );
}

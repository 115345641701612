// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AttributeSelectDialog__dialog__Yak6P .ant-modal-title{font-size:20px;font-weight:600;line-height:28px}.AttributeSelectDialog__dialog__Yak6P .ant-modal-header{border-radius:8px}.AttributeSelectDialog__dialog__Yak6P .ant-modal-content{border-radius:8px !important}.AttributeSelectDialog__tableContainer__GYUVq{height:280px;width:100%}.AttributeSelectDialog__tableTitle__qFCVI{padding-left:16px;font-size:16px;font-weight:600;margin-bottom:4px}.AttributeSelectDialog__divider__IHaX1{width:100%;height:1px;border:1px solid var(--grey-neutral500-UIKit);margin-bottom:8px;margin-top:8px}", "",{"version":3,"sources":["webpack://./src/modules/Report/AttributeSelectDialog/AttributeSelectDialog.scss"],"names":[],"mappings":"AAEQ,uDACI,cAAA,CACA,eAAA,CACA,gBAAA,CAGJ,wDACI,iBAAA,CAEJ,yDACI,4BAAA,CAKZ,8CACI,YAAA,CACA,UAAA,CAGJ,0CACI,iBAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA,CAGJ,uCACI,UAAA,CACA,UAAA,CACA,6CAAA,CACA,iBAAA,CACA,cAAA","sourcesContent":[".dialog {\r\n    :global {\r\n        .ant-modal-title {\r\n            font-size: 20px;\r\n            font-weight: 600;\r\n            line-height: 28px;\r\n        }\r\n\r\n        .ant-modal-header {\r\n            border-radius: 8px;\r\n        }\r\n        .ant-modal-content {\r\n            border-radius: 8px !important;\r\n        }\r\n    }\r\n}\r\n\r\n.tableContainer {\r\n    height: 280px;\r\n    width: 100%;\r\n}\r\n\r\n.tableTitle {\r\n    padding-left: 16px;\r\n    font-size: 16px;\r\n    font-weight: 600;\r\n    margin-bottom: 4px;\r\n}\r\n\r\n.divider {\r\n    width: 100%;\r\n    height: 1px;\r\n    border: 1px solid var(--grey-neutral500-UIKit);\r\n    margin-bottom: 8px;\r\n    margin-top: 8px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "AttributeSelectDialog__dialog__Yak6P",
	"tableContainer": "AttributeSelectDialog__tableContainer__GYUVq",
	"tableTitle": "AttributeSelectDialog__tableTitle__qFCVI",
	"divider": "AttributeSelectDialog__divider__IHaX1"
};
export default ___CSS_LOADER_EXPORT___;

import { defineMessages } from 'react-intl';

export default defineMessages({
    colSettings: {
        id: 'ReportEditorSidePanel.colSettings',
        defaultMessage: 'Настройки колонки',
    },
    attributeType: {
        id: 'ReportEditorSidePanel.attributeType',
        defaultMessage: 'Тип атрибута',
    },
    SYSTEM: {
        id: 'ReportEditorSidePanel.SYSTEM',
        defaultMessage: 'Системный',
    },
    USER: {
        id: 'ReportEditorSidePanel.USER',
        defaultMessage: 'Пользовательский',
    },
    attributePlaceholder: {
        id: 'ReportEditorSidePanel.attributePlaceholder',
        defaultMessage: 'Укажите атрибут',
    },
    deleteColumn: {
        id: 'ReportEditorSidePanel.deleteColumn',
        defaultMessage: 'Удалить колонку',
    },
});

import { fetchNodesWithAttributes } from '@/actions/nodes.actions';
import { useAutoSave } from '@/hooks/useAutoSave';
import { EditorMode } from '@/models/editorMode';
import { TReportTabType } from '@/models/tab.types';
import { Icon } from '@/modules/UIKit';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { TableUIKit } from '@/modules/UIKit/components/Table/TableUIKit.component';
import { TColumn, TTableData } from '@/modules/UIKit/components/Table/TableUIKit.types';
import addIcon from '@/resources/icons/Add_Other.svg';
import noData from '@/resources/icons/noData.svg';
import { NodeId, ReportColumnData, ReportData, ReportNode } from '@/serverapi/api';
import { xor } from 'lodash-es';
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { reportAddColumn, reportDropNodes, reportSaveRequest, reportSelectColumn } from '../actions/report.actions';
import { ReportEditorSidePanel } from '../ReportEditorSidebar/ReportEditorSidePanel.component';
import { ReportSelectors } from '../selectors/report.selectors';
import { ADD_COLUMN_ID } from '../utils/report.const';
import theme from './ReportEditor.scss';
import messages from './ReportEditor.messages';

type TReportEditorProps = {
    tab: TReportTabType;
};

type TReportColumn = TColumn & {
    columnId?: string;
    selected?: boolean;
};

const COLUMN_WIDTH = 160;

export const ReportEditor: FC<TReportEditorProps> = ({ tab: { nodeId, mode } }) => {
    const report: ReportNode | undefined = useSelector(ReportSelectors.byId(nodeId));
    const reportData: ReportData | undefined = report?.reportData;
    const isReadMode: boolean = mode === EditorMode.Read;
    const reportColumns: ReportColumnData[] = reportData?.columns || [];
    const manuallyFilledNodes: NodeId[] = reportData?.manuallyFilledNodes || [];
    const selectedColumnId: string | undefined = useSelector(ReportSelectors.selectedColumnId(nodeId));

    const [checedIds, setCheckedIds] = useState<string[]>([]);

    const tableData: TTableData[] = useSelector(
        ReportSelectors.getReportTableData(nodeId, manuallyFilledNodes, checedIds),
    );

    const dispatch = useDispatch();
    const intl = useIntl();

    const saveReportHandler = () => {
        if (report) dispatch(reportSaveRequest(report));
    };

    useAutoSave({ isEditMode: !isReadMode, nodeId, dependences: [report], callback: saveReportHandler });

    useEffect(() => {
        dispatch(fetchNodesWithAttributes(manuallyFilledNodes));
    }, []);

    const handleAddColumn = () => {
        dispatch(reportAddColumn(nodeId, selectedColumnId));
    };

    const columns: TReportColumn[] = reportColumns
        .sort((a, b) => a.orderNumber - b.orderNumber)
        .map(
            (reportColumn) =>
                ({
                    title: reportColumn.columnName || '',
                    dataKey: `${reportColumn.attributeType}_${reportColumn.attributeTypeId}`,
                    withoutSorter: true,
                    columnId: reportColumn.columnId,
                    selected: reportColumn.columnId === selectedColumnId,
                    width: COLUMN_WIDTH,
                } as TReportColumn),
        );

    const currentColumn: ReportColumnData | undefined = reportColumns.find(
        (column) => column.columnId === selectedColumnId,
    );

    const onDropHandler = (event: React.DragEvent) => {
        const droppedNodeId: NodeId = JSON.parse(event.dataTransfer.getData('nodeId'));
        dispatch(reportDropNodes(nodeId, droppedNodeId));
    };

    const onColumnClickHandler = ({ columnData }) => {
        if (isReadMode) return;

        if (columnData?.columnId && columnData.columnId !== ADD_COLUMN_ID) {
            dispatch(reportSelectColumn(nodeId, columnData.columnId));
        }
    };

    const checkRowHandler = (rows) => {
        const ids: string[] = rows.map((row) => row.id);
        if (ids.length === 1) {
            setCheckedIds((prevIds) => xor(prevIds, ids));
        } else {
            if (rows.length > checedIds.length) {
                setCheckedIds(ids);
            } else {
                setCheckedIds([]);
            }
        }
    };

    const disableDelete: boolean = reportColumns.filter((col) => col.columnName).length < 2;

    return (
        <div className={theme.container}>
            <div
                onDrop={onDropHandler}
                onDragOver={(event: React.DragEvent) => {
                    if (!isReadMode) {
                        event.preventDefault();
                    }
                }}
                className={`${theme.tableContainer} ${tableData.length === 0 ? theme.noData : ''} `}
            >
                <TableUIKit
                    withCkeckBox
                    checkRows={checkRowHandler}
                    columns={columns}
                    tableData={tableData}
                    onHeaderClick={onColumnClickHandler}
                    onColumnClick={onColumnClickHandler}
                    minWidth={columns.length * COLUMN_WIDTH + 60}
                    disableRowClick
                />
                {tableData.length === 0 && (
                    <div className={theme.noDataWrapper}>
                        <Icon spriteSymbol={noData} className={theme.noDataIcon} />
                        <div>{intl.formatMessage(messages.noData)}</div>
                    </div>
                )}
            </div>
            {!isReadMode && (
                <div className={theme.addButtonContainer}>
                    <Button icon={addIcon} visualStyle="text" onClick={handleAddColumn} />
                </div>
            )}
            {currentColumn && (
                <ReportEditorSidePanel
                    disableDelete={disableDelete}
                    reportNodeId={nodeId}
                    currentColumn={currentColumn}
                />
            )}
        </div>
    );
};

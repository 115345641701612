import { ApiBundle } from '../api/api-bundle';
import { getOrigin, replaceLastSlash } from '../../utils/url.utils';
import { Node, NodeId, SearchRequest, SearchResponse, SearchResult, SeveralSearchRequest } from '@/serverapi/api';
import { compareNodeIds, setServerIdToNodeInterface } from '@/utils/nodeId.utils';
import { groupBy } from 'lodash-es';

export class SearchDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async getExtendedSearchResponse(searchRequest: SearchRequest): Promise<SearchResponse> {
        const api = this.getApi();
        const searchResponse: SearchResponse = await api.search.searchExtended({ body: searchRequest });

        return {
            resultList: searchResponse.resultList
                ? searchResponse.resultList.map((result) => ({
                      ...result,
                      nodeId: { ...result.nodeId, serverId: searchRequest.rootSearchNodeId.serverId },
                  }))
                : [],
            foundElementsCount: searchResponse.foundElementsCount,
        };
    }

    public static async getSeveralExtendedSearchResponse(
        serverId: string,
        severalSearchRequest: SeveralSearchRequest,
    ): Promise<SearchResult[] | undefined> {
        const api = this.getApi();
        const searchResponse: SearchResponse = await api.search.severalSearchExtended({ body: severalSearchRequest });

        return searchResponse.resultList?.map((searchResult) => ({
            ...searchResult,
            nodeId: {
                ...searchResult.nodeId,
                serverId,
            },
        }));
    }

    public static async getNodesWithAttributes(serverId: string, nodeIds: NodeId[]): Promise<Node[]> {
        const api = this.getApi();

        const nodes: Node[] = await api.tree.loadBulk({ body: nodeIds });
        nodes.forEach((n) => setServerIdToNodeInterface(n, serverId));

        const groppedNodeIds: { [id: string]: NodeId[] } = groupBy(nodeIds, (nodeId: NodeId) => nodeId.repositoryId);

        const searchRequests: SearchRequest[] = Object.keys(groppedNodeIds).map((repositoryId) => {
            const nodeIds: NodeId[] = groppedNodeIds[repositoryId];
            return {
                rootSearchNodeId: {
                    id: repositoryId,
                    repositoryId: repositoryId,
                    serverId,
                },
                includeAttributes: true,
                searchRules: [
                    {
                        attributeType: 'SYSTEM',
                        attributeTypeId: 'nodeId',
                        queryRule: 'EQUALS',
                        values: nodeIds.map((nodeId) => nodeId.id),
                    },
                ],
            };
        });

        const searchResults: SearchResult[] | undefined = await this.getSeveralExtendedSearchResponse(serverId, {
            requests: searchRequests,
        });

        nodes.forEach((node) => {
            const searchResultForNode: SearchResult | undefined = searchResults?.find((searchResult) =>
                compareNodeIds(searchResult.nodeId, node.nodeId),
            );
            if (searchResultForNode) {
                node.attributes = searchResultForNode.attributes;
            }
        });

        return nodes;
    }
}
